import axios from "@/plugins/axios.js"

const actions = {
  fetchItems({ commit, state}, payload = {}) {
    return new Promise((resolve, reject) => {
      axios.get(`/collections?${new URLSearchParams(payload).toString()}`)
        .then((response) => {
          if(response.data.success){
            commit('SET_ITEMS', response.data.data)
          }
          resolve(response)
        }).catch((error) => { reject(error) })
    })
  },
  fetchAllItems({ commit, state }, force = false) {
    return new Promise((resolve, reject) => {
      if(!force && state.list.length) return resolve(true)
      axios.get("/collections/all")
        .then((response) => {
          if(response.data.success){
            commit('SET_ALL_ITEMS', response.data.data)
          }
          resolve(response)
        }).catch((error) => { reject(error) })
    })
  },
}

const getters = {
   
}


const mutations = {
  ADD_ITEM(state, item){
    state.pageData.data.unshift(item);
  },
  SET_ALL_ITEMS(state, list){
    state.list = list
  },
  SET_ITEMS(state, data) {
    state.pageData = data
  },
  DELETE_ITEM(state, itemId){
    const itemIndex = state.pageData.data.findIndex((item) => item.id == itemId)
    state.pageData.data.splice(itemIndex, 1)
  },
}

const state = {
  list: [],
  pageData: {
    data: []
  },
}


export default {
  isRegistered: true,
  namespaced: true,
  state: state,
  mutations: mutations,
  actions: actions,
  getters: getters
}


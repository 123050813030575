
import axios from "@/plugins/axios.js"

const actions = {
  fetchUsers({ commit, state}, payload = {}) {
    return new Promise((resolve, reject) => {
      axios.get(`/users?${new URLSearchParams(payload).toString()}`)
        .then((response) => {
          if(response.data.success){
            commit('SET_USERS', response.data.data)
          }
          resolve(response)
        }).catch((error) => { reject(error) })
    })
  },
  fetchHeads({ commit, state }) {
    return new Promise((resolve, reject) => {
      axios.get("/users/heads")
        .then((response) => {
          if(response.data.success){
            commit('SET_HEADS', response.data.data)
          }
          resolve(response)
        }).catch((error) => { reject(error) })
    })
  },
  fetchLawyers({ commit, state }) {
    return new Promise((resolve, reject) => {
      axios.get("/users/lawyers")
        .then((response) => {
          if(response.data.success){
            commit('SET_LAWYERS', response.data.data)
          }
          resolve(response)
        }).catch((error) => { reject(error) })
    })
  },
  fetchRoles({ commit, state }, force = false) {
    return new Promise((resolve, reject) => {
      if(!force && state.roles.length) return resolve(true)
      axios.get("/users/roles")
        .then((response) => {
          if(response.data.success){
            commit('SET_ROLES', response.data.data)
          }
          resolve(response)
        }).catch((error) => { reject(error) })
    })
  },
  fetchUser({ commit }, userId) {
    return new Promise((resolve, reject) => {
      axios.get(`/users/${userId}/show`)
        .then((response) => {
          if(response.data.success){
            commit('SET_USER', response.data.data)
          }
          resolve(response)
        }).catch((error) => { reject(error) })
    })
  },
}

const mutations = {
  ADD_USER(state, user) {
    state.pageData.data.push(user)
  },
  SET_USERS(state, data) {
    state.pageData = data
  },
  SET_ROLES(state, data) {
    state.roles = data
  },
  SET_HEADS(state, data) {
    state.heads = data
  },
  SET_LAWYERS(state, data) {
    state.lawyers = data
  },
  SET_USER(state, item) {
    state.user = item
  },
  UPDATE_USER(state, item) {
    Object.assign(state.user, item)
  },
  DELETE_USER(state, userId) {
    state.user = {}
    const userIndex = state.pageData.data.findIndex((u) => u.id == userId)
    state.pageData.data.splice(userIndex, 1)
  },
}

  
const getters = {
   
}

const state = {
    pageData: {
      data: []
    },
    roles: [],
    user: null,
    heads: [],
    lawyers:[]
}

export default {
    isRegistered: true,
    namespaced: true,
    state: state,
    mutations: mutations,
    getters: getters,
    actions: actions,
}
  
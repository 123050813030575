import env from '../env.js'

export const isUserLoggedIn = () => {
  return localStorage.getItem(env.localUserKey) ? true : false
}

export const getUserData = () => {
    const getLocalUser = localStorage.getItem(env.localUserKey);
    if(!getLocalUser){ return null}
    const storedData =  JSON.parse(unescape(atob(getLocalUser)))
    return typeof storedData === 'object' && storedData !== null
         ? storedData.user
         : null

}

export const getUserStorable = (user) => {
  if(!user) {return null}
  let allowed = ['id', 'last_name', 'first_name','role', 'email', 'mobile',
                  'image', 'status', 'gender', 'is_verified'];
  return Object.keys(user)
        .filter(key => allowed.includes(key))
        .reduce((obj, key) => { obj[key] = user[key]; return obj;}, {});
}
